<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="passwordResetRules">
      <b-form
          method="POST"
          @submit.prevent="validationForm"
      >
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
                label="Huidige wachtwoord"
                label-for="account-old-password"
            >
              <validation-provider
                  #default="{ errors }"
                  name="old-password"
                  vid="account-old-password"
                  rules="required"
              >
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                      id="account-old-password"
                      v-model="passwordValueOld"
                      name="old-password"
                      :type="passwordFieldTypeOld"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Oud wachtwoord"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        :icon="passwordToggleIconOld"
                        class="cursor-pointer"
                        @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
                label-for="account-new-password"
                label="Nieuw wachtwoord"
            >
              <validation-provider
                  #default="{ errors }"
                  name="passwordValueOld"
                  vid="Password"
                  rules="required|password"
              >
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                      id="account-new-password"
                      v-model="newPasswordValue"
                      :type="passwordFieldTypeNew"
                      name="new-password"
                      placeholder="Nieuw wachtwoord"
                      :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
                label-for="account-retype-new-password"
                label="Bevestig nieuw wachtwoord"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
              >
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                      id="account-retype-new-password"
                      v-model="RetypePassword"
                      :type="passwordFieldTypeRetype"
                      name="retype-password"
                      placeholder="Nieuw wachtwoord"
                      :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->
        </b-row>
        <hr class="invoice-spacing">
        <b-card-body class="invoice-padding pt-0">
          <b-row>
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="secondary"
                class="mt-1 mr-1"
              >
                Reset
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1"
                type="submit"
              >
                Opslaan
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import store from '@/store'
import { required } from '@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    validationForm(event) {
      this.$refs.passwordResetRules.validate().then(success => {
        if (success) {
          store.dispatch('app-settings-account/setPassword', { newPassword: this.newPasswordValue, oldPassword: this.passwordValueOld })
            .then(response => {
              event.target.reset()
              if (response.data.success) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'U wachtwoord is succesvol gewijzigd',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
              }

              if (!response.data.success) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Wachtwoord wijzigen',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer later het nog een keer.',
                  },
                })
              }
            })
            .catch(() => {
              event.target.reset()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Wachtwoord wijzigen',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer later het nog een keer.',
                },
              })
            })
        }
      })
    },
  },
}
</script>
