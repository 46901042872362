<template>
  <div>
    <b-modal
      id="modal-idin"
      ref="modalIdin"
      hide-footer
      centered
      size="md"
      title="iDIN verificatie"
      :no-close-on-backdrop="false"
    >
      <b-card-text>
        <template #title>
          <feather-icon icon="UserPlusIcon"/>
          <span>iDIN verificatie</span>
        </template>

        <b-card-text>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormSearchRelationObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(verifyIdin)"
            >
              <b-card-text>
                iDIN is een dienst van de banken waarmee hun klanten zich bij andere organisaties online kunnen
                identificeren.
                <br/><br/>
                Jij kunt je bij ons identificeren met de veilige en vertrouwde inlogmiddelen van jouw eigen bank. Meer
                informatie is te vinden op www.idin.nl.
                <br/><br/>
                Jouw legitimatiebewijs dient overeen te komen met de tenaamstelling van je privé-rekening.
                <hr/>
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <!-- Country -->
                    <validation-provider
                      #default="validationContext"
                      name="idinBanks"
                      rules="required"
                    >
                      <b-form-group
                        label="Selecteer een bank"
                        label-for="idinBanks"
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          v-model="selectedIdinBank"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="idinBanks"
                          :reduce="val => val.issuer_id"
                          label="issuer_name"
                          :clearable="false"
                          input-id="idinBanks"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card-text>
              <b-row>
                <b-col cols="12 text-right mb-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                  >
                    <span>Ga door</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-card-text>
    </b-modal>
    <!--/ media -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refProfileFormObserver"
    >
      <!-- form -->
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-card>
          <b-row>
            <b-col cols="12" md="5">
              <b-form-group
                label="Voornaam"
                label-for="firstname"
              >
                <b-form-input
                  v-model="profile.firstName"
                  name="firstname"
                  disabled="disabled"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="2">
              <b-form-group
                label="Tussenvoegsel"
                label-for="middleName"
              >
                <b-form-input
                  v-model="profile.middleName"
                  name="middleName"
                  disabled="disabled"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="5">
              <b-form-group
                label="Achternaam"
                label-for="lastName"
              >
                <b-form-input
                  v-model="profile.lastName"
                  name="lastName"
                  disabled="disabled"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="Postcode"
                label-for="postalCode"
              >
                <b-form-input
                  v-model="profile.postalCode"
                  name="postalCode"
                  disabled="disabled"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="Huisnummer"
                label-for="houseNumber"
              >
                <b-form-input
                  v-model="profile.houseNumber"
                  name="houseNumber"
                  disabled="disabled"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="Straat"
                label-for="street"
              >
                <b-form-input
                  v-model="profile.street"
                  name="street"
                  disabled="disabled"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="Woonplaats"
                label-for="city"
              >
                <b-form-input
                  v-model="profile.city"
                  name="city"
                  disabled="disabled"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="E-mail"
                label-for="email"
              >
                <b-form-input
                  v-model="profile.email"
                  name="email"
                  trim
                  disabled="disabled"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="phone"
                rules="required|integer"
              >
                <b-form-group
                  label="Telefoon"
                  label-for="phone"
                >
                  <b-form-input
                    v-model="profile.phone"
                    type="text"
                    name="phone"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="iban"
                rules="required"
              >
                <b-form-group
                  label="IBAN rekeningnummer"
                  label-for="iban"
                >
                  <b-form-input
                    v-model="profile.iban"
                    name="iban"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="Geboortedatum"
                label-for="birthdate"
              >
                <flat-pickr
                  id="birthdate"
                  v-model="profile.birthdate"
                  :config="birthDateConfig"
                  disabled="disabled"
                >
                </flat-pickr>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="Burgerservicenummer"
                label-for="bsnNumber"
              >
                <b-form-input
                  v-model="profile.bsnNumber"
                  trim
                  disabled="disabled"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0 pb-0">
            <b-row>
              <b-col
                cols="12"
                class="text-right"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  type="button"
                  @click="openModalIdin"
                  class="mt-1 mr-1"
                >
                  <span>Bijwerken met iDIN</span>
                </b-button>
                <b-button
                  class="mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                >
                  <span>Opslaan</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  email,
  required,
  integer,
  length,
} from '@validations'
import 'flatpickr/dist/flatpickr.css'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  VBToggle,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCardBody,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    profileData: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      required,
      email,
      integer,
      length,
      clevePhone: {
        phone: true,
        phoneRegionCode: 'NL',
      },
      profile: {},
      birthDateConfig: {
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
    }
  },
  watch: {
    profileData: {
      deep: true,
      immediate: true,
      async handler(data) {
        this.profile = JSON.parse(JSON.stringify(data))
      },
    },
  },
  methods: {
    openModalIdin() {
      this.getIdinBanks()
    },
    verifyIdin() {
      store
        .dispatch('app-settings-account/idinValidation', { bankID: this.selectedIdinBank })
        .then(response => {
          window.location.replace(response.data.url)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'iDIN verificatie',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het openen van de iDIN verificatie.',
            },
          })
        })
    },
    getIdinBanks() {
      store
        .dispatch('app-settings-account/fetchIdinBanks')
        .then(response => {
          this.idinBanks = response.data[0].issuers
          this.$refs.modalIdin.show()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ophalen iDIN bankenlijst',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    onSubmit() {
      const data = {
        iban: this.profile.iban,
        phone: this.profile.phone,
      }

      store.dispatch('app-settings-account/patchProfile', data)
        .then(response => {
          this.$emit('set-profile-data', response.data)
          this.showUploadLoader = false
          this.$swal({
            icon: 'success',
            title: 'Profiel',
            text: 'Je profiel is bijgewerkt',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.userData = undefined
          this.showUploadLoader = false
          this.$swal({
            icon: 'error',
            title: 'Uploaden',
            text: 'Er is een fout opgetreden bij het bijwerken van je profiel. Probeer later het nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
}
</script>
