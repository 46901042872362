<template>
  <b-card
    title="Gegevens"
  >
    <!-- User Info: Input Fields -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        v-if="profile"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Bedrijfsnaam"
              rules=""
            >
              <b-form-group
                label="Bedrijfsnaam"
                label-for="companyName"
              >
                <b-form-input
                  id="companyName"
                  v-model="profileData.name"
                  :state="getValidationState(validationContext)"
                  disabled="disabled"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="KvK-nr"
              rules=""
            >
              <b-form-group
                label="KvK-nr"
                label-for="cocNumber"
              >
                <b-form-input
                  id="cocNumber"
                  v-model="profileData.cocNumber"
                  :state="getValidationState(validationContext)"
                  disabled="disabled"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Straat"
              rules="required"
            >
              <b-form-group
                label="Straat"
                label-for="street"
              >
                <b-form-input
                  id="street"
                  v-model="profile.street"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
              #default="validationContext"
              name="Huisnummer"
              rules="required"
            >
              <b-form-group
                label="Huisnummer"
                label-for="houseNumber"
              >
                <b-form-input
                  id="houseNumber"
                  v-model="profile.houseNumber"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
              #default="validationContext"
              name="houseNumberAddition"
              rules=""
            >
              <b-form-group
                label="Toevoeging"
                label-for="houseNumberAddition"
              >
                <b-form-input
                  id="houseNumberAddition"
                  v-model="profile.houseNumberAddition"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Postcode"
              rules="required"
            >
              <b-form-group
                label="Postcode"
                label-for="postalCode"
              >
                <b-form-input
                  id="postalCode"
                  v-model="profile.postalCode"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Plaats"
              rules="required"
            >
              <b-form-group
                label="Plaats"
                label-for="city"
              >
                <b-form-input
                  id="city"
                  v-model="profile.city"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="5"
          >
            <validation-provider
              #default="validationContext"
              name="Voornaam"
              rules="required"
            >
              <b-form-group
                label="Voornaam"
                label-for="firstName"
              >
                <b-form-input
                  id="firstName"
                  v-model="profile.firstName"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <validation-provider
              #default="validationContext"
              name="Tussenvoegsel"
              rules="required"
            >
              <b-form-group
                label="Tussenvoegsel"
                label-for="middleName"
              >
                <b-form-input
                  id="middleName"
                  v-model="profile.middleName"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <validation-provider
              #default="validationContext"
              name="Achternaam"
              rules="required"
            >
              <b-form-group
                label="Achternaam"
                label-for="lastName"
              >
                <b-form-input
                  id="lastName"
                  v-model="profile.lastName"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="E-mailadres"
              rules="required|email"
            >
              <b-form-group
                label="E-mailadres"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="profile.email"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Telefoon"
              rules="required|integer"
            >
              <b-form-group
                label="Telefoon"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  type="text"
                  v-model="profile.phone"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="BTW-Nummer"
              rules="regex:^(NL)?[0-9]{9}B[0-9]{2}$"
            >
              <b-form-group
                label="BTW-Nummer"
                label-for="vatNumber"
              >
                <b-form-input
                  id="vatNumber"
                  v-model="profile.vatNumber"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Betaaltermijn"
              rules="required"
            >
              <b-form-group
                label="Betaaltermijn"
                label-for="paymentTermDays"
              >
                <b-form-input
                  id="paymentTermDays"
                  v-model="profile.paymentTermDays"
                  :state="getValidationState(validationContext)"
                  type="number"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row
          class="mt-2"
        >
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              <b-spinner
                v-if="updateProfileSpinner"
                small
                class="mr-1">

              </b-spinner>
              <span>Opslaan</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  email,
  required,
  integer,
  length,
  regex,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow, BSpinner,
  VBToggle,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    profileData: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      regex,
      length,
      required,
      email,
      integer,
      profile: null,
      updateProfileSpinner: false,
    }
  },
  watch: {
    profileData: {
      deep: true,
      immediate: true,
      async handler(data) {
        if (data) {
          this.profile = JSON.parse(JSON.stringify(data))
        }
      },
    },
  },
  methods: {
    onSubmit() {
      this.updateProfileSpinner = true
      store.dispatch('app-settings-account/patchCompanyProfile', this.profile)
        .then(response => {
          this.$emit('set-company-profile-data', response.data)
          this.updateProfileSpinner = false
          this.$swal({
            icon: 'success',
            title: 'Bedrijfsgegevens',
            text: 'Je bedrijfsgegevens zijn bijgewerkt!!!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.updateProfileSpinner = false
          this.$swal({
            icon: 'error',
            title: 'Bedrijfsgegevens bijwerken',
            text: 'Er is een fout opgetreden bij het bijwerken van de bedrijfsgegevens. Probeer later het nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
}
</script>
