<template>
  <div>
    <!--/ media -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refProfileFormObserver"
    >
      <!-- form -->
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-card>
          <b-row>
            <b-col cols="12" md="5">
              <validation-provider
                #default="validationContext"
                name="Voornaam"
                rules="required"
              >
                <b-form-group
                  label="Voornaam"
                  label-for="firstName"
                >
                  <b-form-input
                    id="firstname"
                    v-model="profile.firstName"
                    name="firstname"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="2">
              <b-form-group
                label="Tussenvoegsel"
                label-for="middleName"
              >
                <b-form-input
                  v-model="profile.middleName"
                  name="middleName"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="5">
              <validation-provider
                #default="validationContext"
                name="Achternaam"
                rules="required"
              >
                <b-form-group
                  label="Achternaam"
                  label-for="lastName"
                >
                  <b-form-input
                    v-model="profile.lastName"
                    name="lastName"
                    id="lastName"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="E-mail"
                label-for="email"
              >
                <b-form-input
                  v-model="profile.email"
                  name="email"
                  trim
                  disabled="disabled"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="phone"
                rules="required|integer"
              >
                <b-form-group
                  label="Telefoon"
                  label-for="phone"
                >
                  <b-form-input
                    v-model="profile.phone"
                    type="text"
                    name="phone"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0 pb-0">
            <b-row>
              <b-col
                cols="12"
                class="text-right"
              >
                <b-button
                  class="mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                >
                  <span>Opslaan</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { email, required, integer } from '@validations'
import 'flatpickr/dist/flatpickr.css'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  VBToggle,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCardBody,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    profileData: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      required,
      email,
      integer,
      profile: {},
    }
  },
  watch: {
    profileData: {
      deep: true,
      immediate: true,
      async handler(data) {
        this.profile = JSON.parse(JSON.stringify(data))
      },
    },
  },
  methods: {
    onSubmit() {
      const data = {
        iban: this.profile.iban,
        phone: this.profile.phone,
      }

      store.dispatch('app-settings-account/patchProfile', data)
        .then(response => {
          this.$emit('set-profile-data', response.data)
          this.showUploadLoader = false
          this.$swal({
            icon: 'success',
            title: 'Profiel',
            text: 'Je profiel is bijgewerkt',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.userData = undefined
          this.showUploadLoader = false
          this.$swal({
            icon: 'error',
            title: 'Profiel bijwerken',
            text: 'Er is een fout opgetreden bij het aanpassen van je profiel. Probeer later het nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
}
</script>
