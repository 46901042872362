<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab
      v-if="($can('read', 'account-freelancer') && profileData.agreementSigned === 0) || ($can('read', 'account-freelancer') && !profileData.agreementSigned)"
    >
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ShieldIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Verificatie</span>
        <b-badge
          variant="warning"
          class="badge-pill mr-1 ml-auto"
          style="padding-left:9px;padding-right:9px;"
        >
          !
        </b-badge>
      </template>
      <account-setting-general
        :profile-data="profileData"
        @get-profile-data="getProfile()"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- info -->
    <b-tab
      v-if="$can('read', 'account-freelancer')"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Persoonlijke gegevens</span>
      </template>

      <account-setting-information
        v-if="profileData"
        :profile-data="profileData"
        @set-profile-data="setProfileData($event)"
      />
    </b-tab>

    <b-tab
      v-if="$can('read', 'account-company')"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Persoonlijke gegevens</span>
      </template>

      <account-setting-information-profile-company
        v-if="profileData"
        :profile-data="profileData"
        @set-profile-data="setProfileData($event)"
      />
    </b-tab>

    <b-tab
      v-if="$can('read', 'account-company')"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="MapPinIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Bedrijfsgegevens</span>
      </template>
      <account-setting-information-company
        v-if="companyProfileData"
        :profile-data="companyProfileData"
        @set-company-profile-data="setProfileData($event)"
      ></account-setting-information-company>
    </b-tab>

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Wachtwoord wijzigen</span>
      </template>

      <account-setting-password/>
    </b-tab>
    <!--/ change password tab -->

    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="ShieldIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Two-Factor Authenticatie</span>
      </template>

      <account-setting-tfa
        v-if="profileData"
        :profile-data="profileData"
        @update-tfa="updateTFA($event)"
      />
    </b-tab>

    <!-- notification -->
<!--    <b-tab>-->

<!--      &lt;!&ndash; title &ndash;&gt;-->
<!--      <template #title>-->
<!--        <feather-icon-->
<!--          icon="BellIcon"-->
<!--          size="18"-->
<!--          class="mr-50"-->
<!--        />-->
<!--        <span class="font-weight-bold">Notificaties</span>-->
<!--      </template>-->

<!--      <account-setting-notification-->
<!--        v-if="profileData"-->
<!--        :notification-data="options.notification"-->
<!--      />-->
<!--    </b-tab>-->
  </b-tabs>
</template>

<script>
import { BBadge, BTab, BTabs } from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import accountStoreModule from '@/views/pages/account-setting/accountStoreModule'
import AccountSettingTfa from '@/views/pages/account-setting/AccountSettingTfa.vue'
import AccountSettingInformationProfileCompany
  from '@/views/pages/account-setting/AccountSettingInformationProfileCompany.vue'
import AccountSettingInformationCompany from '@/views/pages/account-setting/AccountSettingInformationCompany.vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'

export default {
  components: {
    BTabs,
    BTab,
    BBadge,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingInformationProfileCompany,
    AccountSettingInformationCompany,
    AccountSettingTfa,
  },
  data() {
    return {
      options: {},
      profileData: {},
      companyProfileData: {},
      userData: {},
    }
  },
  beforeMount() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.getProfile()
  },
  methods: {
    getProfile() {
      if (this.userData.type === 1 || this.userData.type === 2) {
        store
          .dispatch('app-settings-account/fetchProfile')
          .then(response => {
            this.profileData = response.data
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ophalen profiel',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
              },
            })
          })
      }

      if (this.userData.type === 2) {
        store
          .dispatch('app-settings-account/fetchCompanyProfile')
          .then(response => {
            this.companyProfileData = response.data
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ophalen profiel',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
              },
            })
          })
      }
    },
    updateTFA(data) {
      store.dispatch('app-settings-account/tfa', data)
        .then(response => {
          if (response.data.success) {
            this.getProfile()
            this.$swal({
              icon: 'success',
              title: 'Gelukt!',
              text: 'De Two-Factor Authenticatie wijziging is succesvol doorgevoerd!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          if (!response.data.success) {
            this.$swal({
              icon: 'error',
              title: 'Mislukt!',
              text: 'De ingevoerde authenticatie code is incorrect!',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            })
          }
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Mislukt!',
            text: 'De ingevoerde authenticatie code is incorrect!',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    setProfileData(data) {
      if (this.userData.type === 1) {
        this.profileData = data
      }
      if (this.userData.type === 2) {
        this.companyProfileData = data
      }
    },
  },
  setup() {
    const ACCOUNT_PAYOUT_APP_STORE_MODULE_NAME = 'app-settings-account'

    // Register module
    if (!store.hasModule(ACCOUNT_PAYOUT_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_PAYOUT_APP_STORE_MODULE_NAME, accountStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNT_PAYOUT_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_PAYOUT_APP_STORE_MODULE_NAME)
    })
  },
}
</script>
<style>

</style>
