import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProfile(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}profile`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompanyProfile(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}companyprofile`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    patchPaymentPreferences(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${axiosDefaultConfig.backendEndPoint}profile/paymentpreferences`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    idinValidation(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}idin/new`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchIdinBanks() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}idin/banks`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}file/upload`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    patchCompanyProfile(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}companyprofile`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    patchProfile(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${axiosDefaultConfig.backendEndPoint}profile`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    validateID(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}profile/validateid`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setPassword(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}changepassword`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAgreement(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}profile/agreement?hasSocialCharges=${data.hasSocialCharges}&hasLoonheffingDiscount=${data.hasLoonHeffingDiscount}`, { responseType: 'arraybuffer' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    signAgreement(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}profile/signagreement`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    tfa(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${axiosDefaultConfig.backendEndPoint}profile/tfa`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
