<template>
  <div>
    <b-modal
      id="modal-idin"
      ref="modalIdin"
      hide-footer
      centered
      size="lg"
      title="iDIN verificatie"
      :no-close-on-backdrop="false"
    >
      <b-card-text>
        <template #title>
          <feather-icon icon="UserPlusIcon"/>
          <span>iDIN verificatie</span>
        </template>

        <b-card-text>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormSearchRelationObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(verifyIdin)"
            >
              <b-card-text>
                iDIN is een dienst van de banken waarmee hun klanten zich bij andere organisaties online kunnen
                identificeren.
                <br/><br/>
                Jij kunt je bij ons identificeren met de veilige en vertrouwde inlogmiddelen van jouw eigen bank. Meer
                informatie is te vinden op www.idin.nl.
                <br/><br/>
                Jouw legitimatiebewijs dient overeen te komen met de tenaamstelling van je privé-rekening.
                <hr/>
                <b-row>
                  <b-col
                    cols="12"
                    lg="12"
                  >
                    <!-- Country -->
                    <validation-provider
                      #default="validationContext"
                      name="idinBanks"
                      rules="required"
                    >
                      <b-form-group
                        label="Selecteer een bank"
                        label-for="idinBanks"
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          v-model="selectedIdinBank"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="idinBanks"
                          :reduce="val => val.issuer_id"
                          label="issuer_name"
                          :clearable="false"
                          input-id="idinBanks"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card-text>
              <b-row>
                <b-col cols="12 text-right mb-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                  >
                    <span>Ga door</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-id-upload"
      ref="modalIdUpload"
      hide-footer
      centered
      size="lg"
      title="Identiteitsbewijs uploaden"
      :no-close-on-backdrop="false"
    >
      <b-card-text>
        <template #title>
          <feather-icon icon="UserPlusIcon"/>
          <span>Identiteitsbewijs uploaden</span>
        </template>

        <b-card-text>
          <validation-observer
            #default="{ handleSubmit }"
          >
            <b-form
              @submit.prevent="handleSubmit(validateID)"
            >
              <b-row>
                <b-col
                  cols="6"
                  lg="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="fileName1"
                    rules="required"
                  >
                    <b-form-group
                      label="ID Voorkant"
                      label-for="fileName1"
                    >
                      <b-form-input
                        id="fileName1"
                        v-model="fileName1"
                        :state="getValidationState(validationContext)"
                        readonly="readonly"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="6"
                  lg="6"
                  class="d-flex align-items-center"
                >
                  <b-button
                    v-if="!fileName1"
                    style="margin-top:10px"
                    v-b-toggle.report-list
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    size="sm"
                    variant="outline-primary"
                    @click="$refs.refInputEl1.click()"
                  >
                    <b-spinner
                      small
                      class="mr-1"
                      v-if="showUploadLoaderIdFile1"
                    />
                    <input
                      ref="refInputEl1"
                      id="refInputEl1"
                      type="file"
                      class="d-none"
                      @input="uploadImage(1)"
                    >
                    <span class="d-none d-sm-inline">Uploaden</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                  <b-button
                    v-if="fileName1"
                    style="margin-top:10px"
                    v-b-toggle.report-list
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    size="sm"
                    variant="outline-primary"
                    @click="removeFile(1)"
                  >
                    <span class="d-none d-sm-inline">Verwijderen</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="6"
                  lg="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="fileName2"
                    rules="required"
                  >
                    <b-form-group
                      label="ID Achterkant"
                      label-for="fileName2"
                    >
                      <b-form-input
                        id="fileName2"
                        v-model="fileName2"
                        :state="getValidationState(validationContext)"
                        readonly="readonly"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="6"
                  lg="6"
                  class="d-flex align-items-center"
                >
                  <b-button
                    v-if="!fileName2"
                    style="margin-top:10px"
                    v-b-toggle.report-list
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    size="sm"
                    variant="outline-primary"
                    @click="$refs.refInputEl2.click()"
                  >
                    <b-spinner
                      small
                      class="mr-1"
                      v-if="showUploadLoaderIdFile2"
                    />
                    <input
                      ref="refInputEl2"
                      id="refInputEl2"
                      type="file"
                      class="d-none"
                      @input="uploadImage(2)"
                    >
                    <span class="d-none d-sm-inline">Uploaden</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                  <b-button
                    v-if="fileName2"
                    style="margin-top:10px"
                    v-b-toggle.report-list
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    size="sm"
                    variant="outline-primary"
                    @click="removeFile(1)"
                  >
                    <span class="d-none d-sm-inline">Verwijderen</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12 text-right mb-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                    :disabled="showUploadLoaderIdFile1 || showUploadLoaderIdFile2"
                  >
                    <b-spinner
                      small
                      class="mr-1"
                      v-if="showValidateIDLoader"
                    />
                    <span>Ga door</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-agreement"
      ref="modalAgreement"
      hide-footer
      centered
      size="xl"
      title="Raamovereenkomst"
      :no-close-on-backdrop="false"
    >
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <b-card>
            <p>
              We leggen onze afspraken vast in een raamovereenkomst, waarin we onze verplichtingen en afspraken over en weer vastleggen. We houden het simpel en overzichtelijk. De overeenkomst gaat in werking op het moment dat alle procedures zijn doorlopen en je identiteit is geverifieerd.
            </p>
          </b-card>
          <b-card>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormSignAgreementObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(acceptAgreement)"
              >
                <validation-provider
                  #default="validationContext"
                  name="iban"
                  rules="required"
                >
                  <b-form-group
                    label="IBAN rekeningnummer"
                    label-for="iban"
                  >
                    <b-form-input
                      v-model="iban"
                      name="iban"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  #default="validationContext"
                  name="jobID"
                  rules="required"
                >
                  <b-form-group
                    label="Beroep"
                    label-for="jobID"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="sectorID"
                      v-model="jobID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="professionOptions"
                      :reduce="val => val.value"
                      label="name"
                      :clearable="false"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
                <b-form-group>
                  <b-form-checkbox
                    name="hasLoonHeffingDiscount"
                    v-model="hasLoonHeffingDiscount"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Loonheffingskorting toepassen
                    <b-link
                      id="hasLoonHeffingDiscount-row-info"
                      class="font-weight-bold"
                      style="color: #29625b;"
                    >
                      <feather-icon icon="InfoIcon" class="ml-1" size="20"/>
                    </b-link>
                    <b-tooltip
                      target="hasLoonHeffingDiscount-row-info"
                      placement="right"
                      custom-class="tooltip-info"
                    >
                      <p>
                        Je kan kiezen om de loonheffingskorting toe te passen, dat doe je meestal bij de partij waar je het meeste inkomen genereert, zo hou je netto het meeste over.
                      </p>
                    </b-tooltip>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox
                    name="hasSocialCharges"
                    v-model="hasSocialCharges"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Sociale premies via UWV toepassen
                    <b-link
                      id="hasSocialCharges-row-info"
                      class="font-weight-bold"
                      style="color: #29625b;"
                    >
                      <feather-icon icon="InfoIcon" class="ml-1" size="20"/>
                    </b-link>
                    <b-tooltip
                      target="hasSocialCharges-row-info"
                      placement="right"
                      custom-class="tooltip-info"
                    >
                      <p>
                        Indien je heb gekozen voor de optie met Sociale verzekeringen, dan houden we dit in op je uitbetaling en dragen we zorg voor de afdracht hiervan. Hiermee kan je aanspraak maken op een aantal voorzieningen vanuit het UWV. Check hiervoor de informatie op www.uwv.nl.
                        </p>
                    </b-tooltip>
                  </b-form-checkbox>
                </b-form-group>
                <validation-provider
                  #default="validationContext"
                  name="agreementAccepted"
                  rules="required"
                >
                  <b-form-group
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-checkbox
                      name="agreementAccepted"
                      v-model="accepted"
                      :state="getValidationState(validationContext)"
                      :value="1"
                      :unchecked-value="0"
                      required
                    >
                      Ik ga akkoord met de raamovereenkomst
                    </b-form-checkbox>
                  </b-form-group>
                </validation-provider>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                  class="mt-1"
                >
                  <b-spinner
                    small
                    class="mr-1"
                    v-if="showSignAgreementLoader"
                  />
                  <span>Teken raamovereenkomst</span>
                </b-button>
              </b-form>
            </validation-observer>
          </b-card>

        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-card>
            <b-row>
              <b-col
                v-if="agreement"
                cols="12"
              >
                <label>{{ page }} / {{ pageCount }}</label>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="page = page - 1"
                  class="ml-1"
                  :disabled="pageCount === '1' || page === 1"
                >
                  <feather-icon icon="ArrowLeftIcon"/>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="page = page + 1"
                  class="ml-1"
                  :disabled="pageCount === page || pageCount === 1"
                >
                  <feather-icon icon="ArrowRightIcon"/>
                </b-button>
                <b-button
                  style="float: right"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  @click="$refs.pdfAgreementViewer.print(300)"
                >
                  <feather-icon icon="PrinterIcon"/>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-overlay
                  :show="showOverlay"
                  rounded="sm"
                >
                  <pdf
                    ref="pdfAgreementViewer"
                    :src="agreement"
                    :page="page"
                    dpi="300"
                    @num-pages="pageCount = $event"
                    @page-loaded="showOverlay = false"
                    @error="error"
                  ></pdf>
                </b-overlay>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="agreement"
                cols="12"
              >
                <label>{{ page }} / {{ pageCount }}</label>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="page = page - 1"
                  class="ml-1"
                  :disabled="pageCount === '1' || page === 1"
                >
                  <feather-icon icon="ArrowLeftIcon"/>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="page = page + 1"
                  class="ml-1"
                  :disabled="pageCount === page || pageCount === 1"
                >
                  <feather-icon icon="ArrowRightIcon"/>
                </b-button>
                <b-button
                  style="float: right"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  @click="$refs.pdfAgreementViewer.print()"
                >
                  <feather-icon icon="PrinterIcon"/>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-card>
      <b-row class="mb-1">
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="ShieldIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Verificatie
            </h4>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="4"
        >
          <b-card-text>
            Voordat wij jou mogen uitbetalen moeten wij jouw identiteit verifieren en een raamovereenkomst getekend
            hebben.
          </b-card-text>
        </b-col>
        <b-col
          cols="12"
          lg="8"
        >
          <template>
            <app-timeline>
              <app-timeline-item
                :variant="profileData.idinVerified !== null && profileData.idinVerified === 1?'success':'warning'"
                :icon="profileData.idinVerified === 1? 'CheckCircleIcon': 'AlertCircleIcon'">

                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>iDIN Verificatie</h6>
                  <!--                <small class="text-muted">2 hours ago</small>-->
                </div>
                <p
                  v-if="profileData.idinVerified === null || profileData.idinVerified === 0"
                >Verifieer je identiteit met iDIN</p>
                <p
                  v-if="profileData.idinVerified === null && profileData.idinVerified === 1"
                >Uw indentiteit is geverifieerd met iDIN</p>
                <b-button
                  v-if="profileData.idinVerified == null || profileData.idinVerified === 0"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  variant="outline-primary"
                  @click="openModalIdin"
                >
                  Verifieer nu
                </b-button>
              </app-timeline-item>
              <app-timeline-item
                :variant="getVariant(profileData,1).variant"
                :icon="getVariant(profileData,1).icon">

                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>ID verificatie</h6>
                  <!--                <small class="text-muted">2 hours ago</small>-->
                </div>
                <p
                  v-if="profileData.idUploaded === 1 && (profileData.idVerified === null || profileData.idVerified === 0)"
                >
                  Uw ID upload is in afwachting van goedkeuring.
                </p>
                <p
                  v-if="profileData.idUploaded === null ||profileData.idUploaded === 0"
                >
                  Upload je paspoort of identiteitsbewijs. <span v-if="profileData.idinVerified === null || profileData.idinVerified === 0">Dit is pas mogelijk nadat uw indentiteit is geverifieerd met iDIN</span>.
                </p>
                <p
                  v-if="profileData.idVerified === 1"
                >
                  Identiteitsbewijs is geverifieerd. U kunt nu de raamovereenkomst doornemen en tekenen om uw account validatie af te ronden.
                </p>
                <b-button
                  v-if="(profileData.idUploaded === null || profileData.idUploaded === 0) && profileData.idinVerified === 1"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  variant="outline-primary"
                  @click="openModalIdUpload"
                >
                  Uploaden
                </b-button>
              </app-timeline-item>
              <app-timeline-item
                :variant="getVariant(profileData,2).variant"
                :icon="getVariant(profileData,2).icon">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>Raamovereenkomst</h6>
                  <!--                <small class="text-muted">2 hours ago</small>-->
                </div>
                <p>Teken de raamovereenkomst, voor het gebruik van onze diensten dien je de raamovereenkomst te tekenen
                  die hiernaast te zien is. <span v-if="(profileData.idinVerified === null || profileData.idinVerified !== 1) || (profileData.idVerified === null || profileData.idVerified !== 1)">Dit is pas mogelijk nadat uw indentiteit is geverifieerd met iDIN en uw identiteitsbewijs geupload en goedgekeurd is.</span></p>
                <b-button
                  v-if="profileData.idinVerified === 1 && profileData.idVerified === 1 && (profileData.idUploaded === null || profileData.agreementSigned === 0)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  variant="outline-primary"
                  @click="getAgreement"
                >
                  <b-spinner
                    small
                    class="mr-1"
                    v-if="showGetAgreementLoader"
                  />
                  <span v-if="!showGetAgreementLoader">Teken nu</span>
                  <span v-if="showGetAgreementLoader">Raamovereenkomst ophalen</span>
                </b-button>
              </app-timeline-item>
            </app-timeline>
          </template>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm, BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback, BLink, BOverlay,
  BRow,
  BSpinner, BTooltip,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@core/utils/validations/validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'
import pdf from 'vue-pdf'

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    AppTimeline,
    AppTimelineItem,
    BFormInvalidFeedback,
    BFormGroup,
    BSpinner,
    BFormCheckbox,
    BOverlay,
    BTooltip,
    BLink,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    pdf,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    profileData: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      profile: {},
      profileFile: null,
      idinBanks: [],
      selectedIdinBank: null,
      fileNumber: null,
      fileName1: null,
      fileName2: null,
      required,
      agreement: null,
      showOverlay: true,
      page: 1,
      pageCount: 0,
      ID: {
        idFIle1: null,
        idFIle2: null,
      },
      showUploadLoaderIdFile1: false,
      showUploadLoaderIdFile2: false,
      showValidateIDLoader: false,
      showGetAgreementLoader: false,
      showSignAgreementLoader: false,
      accepted: [false],
      hasSocialCharges: 0,
      hasLoonHeffingDiscount: 0,
      jobID: null,
      iban: null,
    }
  },
  watch: {
    profileData: {
      deep: true,
      async handler(data) {
        if (data.hasSocialCharges === 1) {
          this.hasSocialCharges = 1
        } else {
          this.hasSocialCharges = 0
        }

        if (data.hasLoonHeffingDiscount === 1) {
          this.hasLoonHeffingDiscount = 1
        } else {
          this.hasLoonHeffingDiscount = 0
        }
      },
    },
    hasSocialCharges: {
      async handler() {
        this.updateAgreement()
      },
    },
    hasLoonHeffingDiscount: {
      async handler() {
        this.updateAgreement()
      },
    },
  },
  methods: {
    openModalIdUpload() {
      this.$refs.modalIdUpload.show()
    },
    openModalIdin() {
      this.getIdinBanks()
    },
    verifyIdin() {
      store
        .dispatch('app-settings-account/idinValidation', { bankID: this.selectedIdinBank })
        .then(response => {
          window.location.replace(response.data.url)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'iDIN verificatie',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het openen van de iDIN verificatie.',
            },
          })
        })
    },
    getIdinBanks() {
      store
        .dispatch('app-settings-account/fetchIdinBanks')
        .then(response => {
          this.idinBanks = response.data[0].issuers
          this.$refs.modalIdin.show()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ophalen iDIN bankenlijst',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    uploadImage(fileNumber) {
      let file = null
      if (fileNumber === 1) {
        this.showUploadLoaderIdFile1 = true
      }

      if (fileNumber === 2) {
        this.showUploadLoaderIdFile2 = true
      }

      if (fileNumber === 1) {
        // eslint-disable-next-line prefer-destructuring
        file = document.querySelector('#refInputEl1').files[0]
      }

      if (fileNumber === 2) {
        // eslint-disable-next-line prefer-destructuring
        file = document.querySelector('#refInputEl2').files[0]
      }

      if (file.size > 10485760) {
        if (fileNumber === 1) {
          this.showUploadLoaderIdFile1 = false
        }

        if (fileNumber === 2) {
          this.showUploadLoaderIdFile2 = false
        }
        this.$swal({
          icon: 'error',
          title: 'Afbeeldingsgrootte',
          text: 'De maximale afbeeldinggrootte is 10mb. Het huidige bestand is te groot.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      } else {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            const fileUploadData = {
              file: reader.result,
              relationModel: 'User',
              name: file.name,
              relationID: userData.id,
            }

            store.dispatch('app-settings-account/uploadFile', fileUploadData)
              .then(response => {
                if (fileNumber === 1) {
                  this.showUploadLoaderIdFile1 = false
                  this.ID.idFile1 = response.data.fileID
                  this.fileName1 = file.name
                }

                if (fileNumber === 2) {
                  this.showUploadLoaderIdFile2 = false
                  this.ID.idFile2 = response.data.fileID
                  this.fileName2 = file.name
                }
              })
              .catch(() => {
                if (fileNumber === 1) {
                  this.fileName1 = null
                  this.showUploadLoaderIdFile1 = false
                }

                if (fileNumber === 2) {
                  this.fileName2 = null
                  this.showUploadLoaderIdFile2 = false
                }
                this.showUploadLoader = false
                this.$swal({
                  icon: 'error',
                  title: 'Uploaden',
                  text: 'Er is een fout opgetreden bij het uploaden van het bestand. Probeer later het nog een keer.',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
              })
          },
          false,
        )

        reader.readAsDataURL(file)
      }
    },
    validateID() {
      this.showValidateIDLoader = true
      store.dispatch('app-settings-account/validateID', this.ID)
        .then(() => {
          this.$refs.modalIdUpload.hide()
          this.$emit('get-profile-data')
          this.showValidateIDLoader = false
        })
        .catch(() => {
          this.showValidateIDLoader = false
          this.$swal({
            icon: 'error',
            title: 'ID verificatie',
            text: 'Er is een fout opgetreden bij het verifiëren van je ID. Probeer later het nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    updateAgreement() {
      this.showOverlay = true
      store.dispatch('app-settings-account/fetchAgreement', { hasSocialCharges: this.hasSocialCharges, hasLoonHeffingDiscount: this.hasLoonHeffingDiscount })
        .then(response => {
          this.showOverlay = false
          this.agreement = { data: response.data }
        })
        .catch(() => {
          this.showOverlay = false
        })
    },
    getAgreement() {
      this.showOverlay = true
      this.showGetAgreementLoader = true
      store.dispatch('app-settings-account/fetchAgreement', { hasSocialCharges: this.hasSocialCharges, hasLoonHeffingDiscount: this.hasLoonHeffingDiscount })
        .then(response => {
          this.showOverlay = false
          this.showGetAgreementLoader = false
          this.agreement = { data: response.data }
          this.$refs.modalAgreement.show()
        })
        .catch(() => {
          this.showOverlay = false
          this.showGetAgreementLoader = false
          this.showUploadLoader = false
          this.$swal({
            icon: 'error',
            title: 'Raamovereenkomst',
            text: 'Er is een fout opgetreden bij het ophalen van de raamovereenkomst. Probeer later het nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    removeFile(fileNumber) {
      if (fileNumber === 1) {
        this.fileName1 = null
      }

      if (fileNumber === 2) {
        this.fileName2 = null
      }
    },
    error() {
      this.showOverlay = false
    },
    getVariant(profileData, type) {
      if (type === 1 && profileData.idVerified === 1) {
        return { variant: 'success', icon: 'CheckCircleIcon' }
      }

      if (type === 1 && profileData.idinVerified === 1 && (!profileData.idVerified || profileData.idVerified === 0)) {
        return { variant: 'warning', icon: 'ClockIcon' }
      }

      if (type === 2 && profileData.agreementSigned === 1) {
        return { variant: 'success', icon: 'CheckCircleIcon' }
      }

      if (type === 2 && profileData.idinVerified === 1 && profileData.idVerified === 1 && (!profileData.agreementSigned && profileData.agreementSigned === 0)) {
        return { variant: 'warning', icon: 'AlertCircleIcon' }
      }

      return { variant: 'secondary', icon: 'CircleIcon' }
    },
    acceptAgreement() {
      this.showSignAgreementLoader = true
      store.dispatch('app-settings-account/signAgreement', {
        hasSocialCharges: this.hasSocialCharges,
        hasLoonHeffingDiscount: this.hasLoonHeffingDiscount,
        jobID: this.jobID,
        iban: this.iban,
      })
        .then(() => {
          this.showSignAgreementLoader = false
          this.$emit('get-profile-data')
          this.$refs.modalAgreement.hide()
        })
        .catch(() => {
          this.$refs.modalAgreement.hide()
          this.showSignAgreementLoader = false
          this.$swal({
            icon: 'error',
            title: 'Raamovereenkomst accepteren',
            text: 'Er is een fout opgetreden bij het accepteren van de raamovereenkomst. Probeer later het nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
  },
  setup() {
    const professionOptions = [{
      name: 'Aannemer',
      value: 1,
    }, {
      name: 'Accountant',
      value: 2,
    }, {
      name: 'Accountmanager',
      value: 3,
    }, {
      name: 'Acteur',
      value: 4,
    }, {
      name: 'Actuaris',
      value: 5,
    }, {
      name: 'Acupuncturist',
      value: 6,
    }, {
      name: 'Administratief medewerker',
      value: 7,
    }, {
      name: 'Advocaat',
      value: 8,
    }, {
      name: 'Agrarisch dienstverlener',
      value: 9,
    }, {
      name: 'Akkerbouwer',
      value: 10,
    }, {
      name: 'Analist (computer)',
      value: 11,
    }, {
      name: 'Apotheker',
      value: 12,
    }, {
      name: 'Arbeidsbemiddelaar',
      value: 13,
    }, {
      name: 'Arbeidsdeskundige',
      value: 14,
    }, {
      name: 'Arbo-adviseur',
      value: 15,
    }, {
      name: 'Architect',
      value: 16,
    }, {
      name: 'Artdirector',
      value: 17,
    }, {
      name: 'Asbestverwijderaar',
      value: 18,
    }, {
      name: 'Assurantieadviseur',
      value: 19,
    }, {
      name: 'Audicien',
      value: 20,
    }, {
      name: 'Auteur',
      value: 21,
    }, {
      name: 'Autoglaszetter/hersteller',
      value: 22,
    }, {
      name: 'Automatiseringsadviseur',
      value: 23,
    }, {
      name: 'Automonteur',
      value: 24,
    }, {
      name: 'Autoschadehersteller',
      value: 25,
    }, {
      name: 'Autospuiter',
      value: 26,
    }, {
      name: 'Bakker (brood en/of banket)',
      value: 27,
    }, {
      name: 'Barista',
      value: 28,
    }, {
      name: 'Bedrijfsarts',
      value: 29,
    }, {
      name: 'Begeleider van mensen met een beperking',
      value: 30,
    }, {
      name: 'Beleggingsadviseur',
      value: 31,
    }, {
      name: 'Bemiddelaar in gastouderschap',
      value: 32,
    }, {
      name: 'Benzinepomphouder',
      value: 33,
    }, {
      name: 'Betonboorder',
      value: 34,
    }, {
      name: 'Betonijzervlechter',
      value: 35,
    }, {
      name: 'Betontimmerman',
      value: 36,
    }, {
      name: 'Betonvloerenlegger',
      value: 37,
    }, {
      name: 'Beveiliger',
      value: 38,
    }, {
      name: 'Beveiligingsadviseur',
      value: 39,
    }, {
      name: 'Bewindvoerder',
      value: 40,
    }, {
      name: 'Binnenhuisarchitect',
      value: 41,
    }, {
      name: 'Binnenvaartschipper',
      value: 42,
    }, {
      name: 'Bloembollenkweker',
      value: 43,
    }, {
      name: 'Bloemenkweker/plantenkweker',
      value: 44,
    }, {
      name: 'Bloemist',
      value: 45,
    }, {
      name: 'Blokkenlijmer',
      value: 46,
    }, {
      name: 'Bodemonderzoeker (inspectie en advies)',
      value: 47,
    }, {
      name: 'Boekbinder',
      value: 48,
    }, {
      name: 'Boomkweker',
      value: 49,
    }, {
      name: 'Boomrooier',
      value: 50,
    }, {
      name: 'Bouwkundig ingenieur',
      value: 51,
    }, {
      name: 'Bouwkundig tekenaar',
      value: 52,
    }, {
      name: 'Cafetariahouder',
      value: 53,
    }, {
      name: 'Cafehouder',
      value: 54,
    }, {
      name: 'Calculator',
      value: 55,
    }, {
      name: 'Cameraman',
      value: 56,
    }, {
      name: 'Campinghouder',
      value: 57,
    }, {
      name: 'Carrosseriebouwer',
      value: 58,
    }, {
      name: 'Cateraar',
      value: 59,
    }, {
      name: 'Champignonkweker',
      value: 60,
    }, {
      name: 'Chauffeur (lading)',
      value: 61,
    }, {
      name: 'Chauffeur (personen)',
      value: 62,
    }, {
      name: 'Chocolatier',
      value: 63,
    }, {
      name: 'Coach',
      value: 64,
    }, {
      name: 'Commercieel directeur',
      value: 65,
    }, {
      name: 'Commissionair',
      value: 66,
    }, {
      name: 'Communicatie adviseur',
      value: 67,
    }, {
      name: 'Componist',
      value: 68,
    }, {
      name: 'Computerreparateur',
      value: 69,
    }, {
      name: 'Constructiebankwerker',
      value: 70,
    }, {
      name: 'Consultant',
      value: 71,
    }, {
      name: 'Controller',
      value: 72,
    }, {
      name: 'Dakdekker',
      value: 73,
    }, {
      name: 'Dansleraar',
      value: 74,
    }, {
      name: 'Decoratieschilder',
      value: 75,
    }, {
      name: 'Decorontwerper',
      value: 76,
    }, {
      name: 'Dierenarts (gemengd)',
      value: 77,
    }, {
      name: 'Dierenarts (gezelschapsdieren)',
      value: 78,
    }, {
      name: 'Dierenarts (landbouwhuisdieren)',
      value: 79,
    }, {
      name: 'Dirigent',
      value: 80,
    }, {
      name: 'Diëtist',
      value: 81,
    }, {
      name: 'Docent',
      value: 82,
    }, {
      name: 'Drogist',
      value: 83,
    }, {
      name: 'Drukker',
      value: 84,
    }, {
      name: 'Edelsmid/goudsmid/zilversmid',
      value: 85,
    }, {
      name: 'Editor (audio-visueel)',
      value: 86,
    }, {
      name: 'Eigenaar van een beletterings- en/of reclamemontagebedrijf',
      value: 87,
    }, {
      name: 'Eigenaar van een broodjeszaak',
      value: 88,
    }, {
      name: 'Eigenaar van een fitnesscentrum',
      value: 89,
    }, {
      name: 'Eigenaar van een glaszettersbedrijf',
      value: 90,
    }, {
      name: 'Eigenaar van een hondenuitlaatservice',
      value: 91,
    }, {
      name: 'Eigenaar van een kalvermesterij',
      value: 92,
    }, {
      name: 'Eigenaar van een kinderdagverblijf',
      value: 93,
    }, {
      name: 'Eigenaar van een lunchroom',
      value: 94,
    }, {
      name: 'Eigenaar van een metaalconstructiebedrijf ',
      value: 95,
    }, {
      name: 'Eigenaar van een motorzaak',
      value: 96,
    }, {
      name: 'Eigenaar van een multifunctioneel centrum',
      value: 97,
    }, {
      name: 'Eigenaar van een reisbureau',
      value: 98,
    }, {
      name: 'Eigenaar van een stomerij',
      value: 99,
    }, {
      name: 'Eigenaar van een stuwadoorsbedrijf',
      value: 100,
    }, {
      name: 'Eigenaar van een supermarkt',
      value: 101,
    }, {
      name: 'Eigenaar van een timmerbedrijf (niet meewerkend)',
      value: 102,
    }, {
      name: 'Eigenaar van een tuincentrum',
      value: 103,
    }, {
      name: 'Eigenaar van een webwinkel (exclusief magazijnwerk)',
      value: 104,
    }, {
      name: 'Eigenaar van een webwinkel (inclusief magazijnwerk)',
      value: 105,
    }, {
      name: 'Eigenaar van een woonwinkel (geen meubels)',
      value: 106,
    }, {
      name: 'Elektromonteur',
      value: 107,
    }, {
      name: 'Elektrotechnisch ingenieur',
      value: 108,
    }, {
      name: 'Engineer',
      value: 109,
    }, {
      name: 'Ergotherapeut',
      value: 110,
    }, {
      name: 'Etaleur',
      value: 111,
    }, {
      name: 'Evenementenorganisator',
      value: 112,
    }, {
      name: 'Expediteur',
      value: 113,
    }, {
      name: 'Exportmanager',
      value: 114,
    }, {
      name: 'Film- en videomaker',
      value: 115,
    }, {
      name: 'Financieel adviseur',
      value: 116,
    }, {
      name: 'Financieel directeur',
      value: 117,
    }, {
      name: 'Fiscaal adviseur',
      value: 118,
    }, {
      name: 'Fitnessinstructeur',
      value: 119,
    }, {
      name: 'Fotograaf',
      value: 120,
    }, {
      name: 'Fruitkweker',
      value: 121,
    }, {
      name: 'Fysiotherapeut',
      value: 122,
    }, {
      name: 'Gastouder',
      value: 123,
    }, {
      name: 'Gastvrouw/gastheer',
      value: 124,
    }, {
      name: 'Geitenhouder',
      value: 125,
    }, {
      name: 'Geluidstechnicus/lichttechnicus',
      value: 126,
    }, {
      name: 'Gerechtsdeurwaarder',
      value: 127,
    }, {
      name: 'Gevelreiniger',
      value: 128,
    }, {
      name: 'Glastuinder',
      value: 129,
    }, {
      name: 'Glaszetter',
      value: 130,
    }, {
      name: 'Glazenwasser',
      value: 131,
    }, {
      name: 'Grafisch ontwerper',
      value: 132,
    }, {
      name: 'Grondwerker',
      value: 133,
    }, {
      name: 'Groothandelaar in',
      value: 134,
    }, {
      name: 'Groothandelaar in bloemen en planten',
      value: 135,
    }, {
      name: 'Groothandelaar in bouwmaterialen',
      value: 136,
    }, {
      name: 'Groothandelaar in food en/of non-food',
      value: 137,
    }, {
      name: 'Groothandelaar in kappersbenodigdheden',
      value: 138,
    }, {
      name: 'Groothandelaar in kleding en textiel',
      value: 139,
    }, {
      name: 'Groothandelaar in vlees',
      value: 140,
    }, {
      name: 'HR adviseur',
      value: 141,
    }, {
      name: 'Handelaar in',
      value: 142,
    }, {
      name: 'Handelaar in autos',
      value: 143,
    }, {
      name: 'Handelaar in autobanden/autovelgen',
      value: 144,
    }, {
      name: 'Handelaar in automaterialen',
      value: 145,
    }, {
      name: 'Handelaar in diervoeding',
      value: 146,
    }, {
      name: 'Handelaar in drank',
      value: 147,
    }, {
      name: 'Handelaar in hout',
      value: 148,
    }, {
      name: 'Handelaar in ijzerwaren',
      value: 149,
    }, {
      name: 'Handelaar in keukens',
      value: 150,
    }, {
      name: 'Handelaar in landbouwmachines',
      value: 151,
    }, {
      name: 'Handelaar in machines',
      value: 152,
    }, {
      name: 'Handelaar in natuursteen',
      value: 153,
    }, {
      name: 'Handelaar in vastgoed',
      value: 154,
    }, {
      name: 'Handelsbemiddelaar',
      value: 155,
    }, {
      name: 'Haptotherapeut',
      value: 156,
    }, {
      name: 'Havenmeester',
      value: 157,
    }, {
      name: 'Heftruckchauffeur',
      value: 158,
    }, {
      name: 'Helpende in zorg en welzijn (o.a. voor Zorgmies)',
      value: 159,
    }, {
      name: 'Hoefsmid',
      value: 160,
    }, {
      name: 'Hondentrimmer',
      value: 161,
    }, {
      name: 'Horecamedewerker',
      value: 162,
    }, {
      name: 'Horlogemaker/klokkenmaker',
      value: 163,
    }, {
      name: 'Hotelmanager',
      value: 164,
    }, {
      name: 'Hovenier',
      value: 165,
    }, {
      name: 'Huidtherapeut',
      value: 166,
    }, {
      name: 'Huisarts',
      value: 167,
    }, {
      name: 'Hypotheekadviseur',
      value: 168,
    }, {
      name: 'Ijssalonhouder',
      value: 169,
    }, {
      name: 'Ijsverkoper',
      value: 170,
    }, {
      name: 'Industrieel ontwerper',
      value: 171,
    }, {
      name: 'Inkoopadviseur',
      value: 172,
    }, {
      name: 'Inseminator',
      value: 173,
    }, {
      name: 'Installateur telecom',
      value: 174,
    }, {
      name: 'Installateur van saunas, whirlpools en zwembaden',
      value: 175,
    }, {
      name: 'Installateur van zonnepanelen',
      value: 176,
    }, {
      name: 'Installatiemonteur',
      value: 177,
    }, {
      name: 'Instrumentmaker',
      value: 178,
    }, {
      name: 'Interieurbouwer',
      value: 179,
    }, {
      name: 'Interim manager',
      value: 180,
    }, {
      name: 'Internetintermediair',
      value: 181,
    }, {
      name: 'Isolatiemonteur',
      value: 182,
    }, {
      name: 'Journalist (binnenland)',
      value: 183,
    }, {
      name: 'Journalist (buitenland)',
      value: 184,
    }, {
      name: 'Juridisch adviseur',
      value: 185,
    }, {
      name: 'Juwelier',
      value: 186,
    }, {
      name: 'Kabel- en buizenlegger',
      value: 187,
    }, {
      name: 'Kapper',
      value: 188,
    }, {
      name: 'Kassenbouwer',
      value: 189,
    }, {
      name: 'Kermisexploitant',
      value: 190,
    }, {
      name: 'Keukenmonteur',
      value: 191,
    }, {
      name: 'Kitter',
      value: 192,
    }, {
      name: 'Kleermaker',
      value: 193,
    }, {
      name: 'Klusjesman',
      value: 194,
    }, {
      name: 'Koerier',
      value: 195,
    }, {
      name: 'Koerier (fiets, bromfiets)',
      value: 196,
    }, {
      name: 'Kok',
      value: 197,
    }, {
      name: 'Konijnenfokker',
      value: 198,
    }, {
      name: 'Koppensneller',
      value: 199,
    }, {
      name: 'Kraamverzorger',
      value: 200,
    }, {
      name: 'Kunstschilder',
      value: 201,
    }, {
      name: 'Landmeter',
      value: 202,
    }, {
      name: 'Landschapsarchitect',
      value: 203,
    }, {
      name: 'Lasser',
      value: 204,
    }, {
      name: 'Lijstenmaker',
      value: 205,
    }, {
      name: 'Logopedist',
      value: 206,
    }, {
      name: 'Loonwerker (agrarisch)',
      value: 207,
    }, {
      name: 'Loonwerker (bouw)',
      value: 208,
    }, {
      name: 'Maaltijdbezorger',
      value: 209,
    }, {
      name: 'Maatschappelijk werker',
      value: 210,
    }, {
      name: 'Machinebouwer',
      value: 211,
    }, {
      name: 'Machinist grond-, water- en wegenbouw',
      value: 212,
    }, {
      name: 'Magazijnmedewerker',
      value: 213,
    }, {
      name: 'Makelaar',
      value: 214,
    }, {
      name: 'Manegehouder',
      value: 215,
    }, {
      name: 'Manicure/nagelstylist',
      value: 216,
    }, {
      name: 'Manueel fysiotherapeut',
      value: 217,
    }, {
      name: 'Marketingadviseur/marktonderzoeker',
      value: 218,
    }, {
      name: 'Marktkoopman',
      value: 219,
    }, {
      name: 'Masseur',
      value: 220,
    }, {
      name: 'Mediator',
      value: 221,
    }, {
      name: 'Medisch specialist',
      value: 222,
    }, {
      name: 'Medisch specialist: KNO-arts',
      value: 223,
    }, {
      name: 'Medisch specialist: anaesthesist',
      value: 224,
    }, {
      name: 'Medisch specialist: cardiochirurg',
      value: 225,
    }, {
      name: 'Medisch specialist: cardioloog',
      value: 226,
    }, {
      name: 'Medisch specialist: chirurg',
      value: 227,
    }, {
      name: 'Medisch specialist: dermatoloog',
      value: 228,
    }, {
      name: 'Medisch specialist: gastro-enteroloog',
      value: 229,
    }, {
      name: 'Medisch specialist: geriater',
      value: 230,
    }, {
      name: 'Medisch specialist: gynaecoloog',
      value: 231,
    }, {
      name: 'Medisch specialist: internist',
      value: 232,
    }, {
      name: 'Medisch specialist: kaakchirurg',
      value: 233,
    }, {
      name: 'Medisch specialist: kinderarts',
      value: 234,
    }, {
      name: 'Medisch specialist: longarts',
      value: 235,
    }, {
      name: 'Medisch specialist: neurochirurg',
      value: 236,
    }, {
      name: 'Medisch specialist: neuroloog',
      value: 237,
    }, {
      name: 'Medisch specialist: oncoloog',
      value: 238,
    }, {
      name: 'Medisch specialist: oogarts',
      value: 239,
    }, {
      name: 'Medisch specialist: orthopedisch chirurg',
      value: 240,
    }, {
      name: 'Medisch specialist: patholoog',
      value: 241,
    }, {
      name: 'Medisch specialist: plastisch chirurg',
      value: 242,
    }, {
      name: 'Medisch specialist: psychiater',
      value: 243,
    }, {
      name: 'Medisch specialist: radioloog',
      value: 244,
    }, {
      name: 'Medisch specialist: radiotherapeut',
      value: 245,
    }, {
      name: 'Medisch specialist: reumatoloog',
      value: 246,
    }, {
      name: 'Medisch specialist: revalidatiearts',
      value: 247,
    }, {
      name: 'Medisch specialist: uroloog',
      value: 248,
    }, {
      name: 'Melkveehouder',
      value: 249,
    }, {
      name: 'Metaalbewerker',
      value: 250,
    }, {
      name: 'Metselaar',
      value: 251,
    }, {
      name: 'Meubelmaker',
      value: 252,
    }, {
      name: 'Meubelontwerper',
      value: 253,
    }, {
      name: 'Meubelstoffeerder',
      value: 254,
    }, {
      name: 'Milieu-adviseur',
      value: 255,
    }, {
      name: 'Modeontwerper',
      value: 256,
    }, {
      name: 'Mondhygienist',
      value: 257,
    }, {
      name: 'Monteur van machines',
      value: 258,
    }, {
      name: 'Muziekleraar',
      value: 259,
    }, {
      name: 'Muziekproducer',
      value: 260,
    }, {
      name: 'Natlakker',
      value: 261,
    }, {
      name: 'Natuursteenbewerker',
      value: 262,
    }, {
      name: 'Nertsenfokker',
      value: 263,
    }, {
      name: 'Notaris',
      value: 264,
    }, {
      name: 'Oefentherapeut',
      value: 265,
    }, {
      name: 'Onderhoudsmonteur',
      value: 266,
    }, {
      name: 'Ongediertebestrijder',
      value: 267,
    }, {
      name: 'Opfokker van jongvee',
      value: 268,
    }, {
      name: 'Opleidingsfunctionaris',
      value: 269,
    }, {
      name: 'Opticien',
      value: 270,
    }, {
      name: 'Organisatieadviseur',
      value: 271,
    }, {
      name: 'Orthodontist',
      value: 272,
    }, {
      name: 'Orthopedisch schoentechnicus',
      value: 273,
    }, {
      name: 'Osteopaat',
      value: 274,
    }, {
      name: 'Paardenhandelaar',
      value: 275,
    }, {
      name: 'Paardentandarts',
      value: 276,
    }, {
      name: 'Paardrij-instructeur',
      value: 277,
    }, {
      name: 'Parketvloerenlegger',
      value: 278,
    }, {
      name: 'Pedagoog',
      value: 279,
    }, {
      name: 'Pedicure',
      value: 280,
    }, {
      name: 'Pensionhouder (dieren)',
      value: 281,
    }, {
      name: 'Pensionhouder/hotelhouder',
      value: 282,
    }, {
      name: 'Personal trainer',
      value: 283,
    }, {
      name: 'Pianostemmer',
      value: 284,
    }, {
      name: 'Pijpfitter',
      value: 285,
    }, {
      name: 'Plaatwerker',
      value: 286,
    }, {
      name: 'Planner',
      value: 287,
    }, {
      name: 'Planoloog',
      value: 288,
    }, {
      name: 'Pluimveehouder',
      value: 289,
    }, {
      name: 'Podiumbouwer',
      value: 290,
    }, {
      name: 'Podotherapeut',
      value: 291,
    }, {
      name: 'Poelier',
      value: 292,
    }, {
      name: 'Predikant',
      value: 293,
    }, {
      name: 'Producent van',
      value: 294,
    }, {
      name: 'Producent van ijs',
      value: 295,
    }, {
      name: 'Productiemedewerker (o.a. voor Pig & Hen)',
      value: 296,
    }, {
      name: 'Programmeur (computer)',
      value: 297,
    }, {
      name: 'Projectmanager',
      value: 298,
    }, {
      name: 'Projectondersteuner',
      value: 299,
    }, {
      name: 'Psycholoog',
      value: 300,
    }, {
      name: 'Psychotherapeut',
      value: 301,
    }, {
      name: 'Raadgevend adviseur',
      value: 302,
    }, {
      name: 'Raam- en kozijnzetter',
      value: 303,
    }, {
      name: 'Re-integratieadviseur',
      value: 304,
    }, {
      name: 'Reclameadviseur',
      value: 305,
    }, {
      name: 'Reclametekenaar',
      value: 306,
    }, {
      name: 'Redacteur',
      value: 307,
    }, {
      name: 'Regisseur',
      value: 308,
    }, {
      name: 'Restauranthouder',
      value: 309,
    }, {
      name: 'Restaurateur van antiek/kunst/meubelen/schilderijen',
      value: 310,
    }, {
      name: 'Rietdekker',
      value: 311,
    }, {
      name: 'Rijlesinstructeur',
      value: 312,
    }, {
      name: 'Rioolreiniger',
      value: 313,
    }, {
      name: 'Rundveehouder',
      value: 314,
    }, {
      name: 'Rundveepedicure',
      value: 315,
    }, {
      name: 'Schade-expert',
      value: 316,
    }, {
      name: 'Schapenhouder',
      value: 317,
    }, {
      name: 'Scheepsbouwer',
      value: 318,
    }, {
      name: 'Schilder/behanger',
      value: 319,
    }, {
      name: 'Schoenmaker (reparatie)',
      value: 320,
    }, {
      name: 'Schoonheidsspecialist',
      value: 321,
    }, {
      name: 'Schoonmaker',
      value: 322,
    }, {
      name: 'Schoonmaker van schepen/fabrieken',
      value: 323,
    }, {
      name: 'Schoorsteenveger',
      value: 324,
    }, {
      name: 'Secretaresse, (directie-)',
      value: 325,
    }, {
      name: 'Secretariele dienstverlening',
      value: 326,
    }, {
      name: 'Slachter',
      value: 327,
    }, {
      name: 'Slager',
      value: 328,
    }, {
      name: 'Slijter',
      value: 329,
    }, {
      name: 'Sloper (gebouwen)',
      value: 330,
    }, {
      name: 'Smid',
      value: 331,
    }, {
      name: 'Sociaal pedagogisch hulpverlener',
      value: 332,
    }, {
      name: 'Sportinstructeur',
      value: 333,
    }, {
      name: 'Standbouwer',
      value: 334,
    }, {
      name: 'Stedenbouwkundige',
      value: 335,
    }, {
      name: 'Steigerbouwer',
      value: 336,
    }, {
      name: 'Stratenmaker',
      value: 337,
    }, {
      name: 'Studiebegeleider',
      value: 338,
    }, {
      name: 'Stukadoor',
      value: 339,
    }, {
      name: 'Stuwadoor',
      value: 340,
    }, {
      name: 'Systeembeheerder (computer)',
      value: 341,
    }, {
      name: 'Systeemontwerper (computer)',
      value: 342,
    }, {
      name: 'Systeemplafondbouwer',
      value: 343,
    }, {
      name: 'Systeemwandbouwer',
      value: 344,
    }, {
      name: 'Tandarts',
      value: 345,
    }, {
      name: 'Tandtechnicus',
      value: 346,
    }, {
      name: 'Tatoeeerder',
      value: 347,
    }, {
      name: 'Taxateur',
      value: 348,
    }, {
      name: 'Technisch adviseur',
      value: 349,
    }, {
      name: 'Technisch tekenaar',
      value: 350,
    }, {
      name: 'Tegelzetter',
      value: 351,
    }, {
      name: 'Tekstschrijver',
      value: 352,
    }, {
      name: 'Tentenbouwer',
      value: 353,
    }, {
      name: 'Timmerman',
      value: 354,
    }, {
      name: 'Tolk',
      value: 355,
    }, {
      name: 'Transportondernemer',
      value: 356,
    }, {
      name: 'Tuinarchitect',
      value: 357,
    }, {
      name: 'Uitgever',
      value: 358,
    }, {
      name: 'Uitvaartverzorger',
      value: 359,
    }, {
      name: 'Uitvoerder',
      value: 360,
    }, {
      name: 'Varkenshouder',
      value: 361,
    }, {
      name: 'Veehandelaar',
      value: 362,
    }, {
      name: 'Veiligheidsdeskundige',
      value: 363,
    }, {
      name: 'Verhuizer',
      value: 364,
    }, {
      name: 'Verhuurder van licht- en geluidsinstallaties',
      value: 365,
    }, {
      name: 'Verkeersregelaar',
      value: 366,
    }, {
      name: 'Verkoper (deur-aan-deur, straat)',
      value: 367,
    }, {
      name: 'Verkoper (telefonisch)',
      value: 368,
    }, {
      name: 'Verkoper/vertegenwoordiger',
      value: 369,
    }, {
      name: 'Verloskundige',
      value: 370,
    }, {
      name: 'Verpleegkundige',
      value: 371,
    }, {
      name: 'Vertaler',
      value: 372,
    }, {
      name: 'Vertegenwoordiger',
      value: 373,
    }, {
      name: 'Verzekeringsgeneeskundige',
      value: 374,
    }, {
      name: 'Verzorger',
      value: 375,
    }, {
      name: 'Viskweker',
      value: 376,
    }, {
      name: 'Voeger',
      value: 377,
    }, {
      name: 'Vollegrondstuinder',
      value: 378,
    }, {
      name: 'Webdesigner',
      value: 379,
    }, {
      name: 'Wegenbouwer',
      value: 380,
    }, {
      name: 'Winkelier in',
      value: 381,
    }, {
      name: 'Winkelier in babyartikelen',
      value: 382,
    }, {
      name: 'Winkelier in boeken',
      value: 383,
    }, {
      name: 'Winkelier in cadeau-artikelen',
      value: 384,
    }, {
      name: 'Winkelier in delicatessen',
      value: 385,
    }, {
      name: 'Winkelier in dierenbenodigdheden',
      value: 386,
    }, {
      name: 'Winkelier in doe-het-zelf producten',
      value: 387,
    }, {
      name: 'Winkelier in elektronica',
      value: 388,
    }, {
      name: 'Winkelier in fietsen en/of bromfietsen',
      value: 389,
    }, {
      name: 'Winkelier in fotoartikelen/fotograaf',
      value: 390,
    }, {
      name: 'Winkelier in groente en fruit',
      value: 391,
    }, {
      name: 'Winkelier in hengelsportartikelen',
      value: 392,
    }, {
      name: 'Winkelier in hobbyartikelen',
      value: 393,
    }, {
      name: 'Winkelier in huishoudelijke artikelen',
      value: 394,
    }, {
      name: 'Winkelier in ijzerwaren',
      value: 395,
    }, {
      name: 'Winkelier in kantoorartikelen',
      value: 396,
    }, {
      name: 'Winkelier in kleding (baby-/kinder-/dames-/heren-)',
      value: 397,
    }, {
      name: 'Winkelier in lingerie',
      value: 398,
    }, {
      name: 'Winkelier in meubels en woonaccessoires',
      value: 399,
    }, {
      name: 'Winkelier in modestoffen en textiel',
      value: 400,
    }, {
      name: 'Winkelier in muziekinstrumenten en bladmuziek',
      value: 401,
    }, {
      name: 'Winkelier in sanitair en tegels',
      value: 402,
    }, {
      name: 'Winkelier in schoenen en lederwaren',
      value: 403,
    }, {
      name: 'Winkelier in sigaren, sigaretten en tabaksartikelen',
      value: 404,
    }, {
      name: 'Winkelier in speelgoed',
      value: 405,
    }, {
      name: 'Winkelier in sport- en outdoorartikelen',
      value: 406,
    }, {
      name: 'Winkelier in telecommunicatieapparatuur',
      value: 407,
    }, {
      name: 'Winkelier in verf en behang',
      value: 408,
    }, {
      name: 'Winkelier in vis',
      value: 409,
    }, {
      name: 'Winkelier in watersportartikelen',
      value: 410,
    }, {
      name: 'Winkelier in witgoed',
      value: 411,
    }, {
      name: 'Winkelier in zuivelproducten',
      value: 412,
    }, {
      name: 'Woningstoffeerder',
      value: 413,
    }, {
      name: 'Yogaleraar/tai chi leraar',
      value: 414,
    }, {
      name: 'Zeefdrukker',
      value: 415,
    }, {
      name: 'Zeevisser',
      value: 416,
    }, {
      name: 'Zeilmaker',
      value: 417,
    }, {
      name: 'Zonweringmonteur',
      value: 418,
    }]

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      axiosDefaultConfig,
      refFormObserver,
      getValidationState,
      professionOptions,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.tooltip-info {
  p {
    text-align: left;
  }
}
</style>
